import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Catfood from './components/catfood/Catfood';
import Bright from './components/bright/Bright';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Bright/>}/>
            <Route path="/cat" element={<Catfood/>}/>
            <Route
                path="*"
                element={
                    <main style={{ padding: "1rem" }}>
                        <p>Did you really expect to find anything here?</p>
                    </main>
                }
            />
        </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
