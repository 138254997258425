import React, {useEffect, useState} from 'react';
import classes from './Catfood.module.scss';

const Catfood = () => {

    const processInput = (inputText) => {
        let splitInput = inputText.split("\n");
        splitInput = splitInput.map(si => {
            if (si[si.length - 1] === ',' || si[si.length - 1] === ';' || si[si.length - 1] === '.') {
                return si.substring(0, si.length - 1);
            }
            return si;
        });
        return splitInput;
    }

    const withNoise = false;

    const floatSource = "Delicious\n" +
        "Marvelous\n" +
        "Amazing\n" +
        "* Cat not included\n" +
        "Astounding\n" +
        "Jaw-dropping\n" +
        "Best in category\n" +
        "Exhilarating\n" +
        "Incredible\n" +
        "10/10\n" +
        "Jolting";

    const floatSourceHandled = processInput(floatSource);

    const [sourceLine, setSourceLine] = useState(0);
    const [floatingTexts, setFloatingTexts] = useState<{ text: string, time: number, key: string, style: any }[]>([]);

    useEffect(() => {
        const textAdder = setInterval(() => {
            const newFloater = {
                text: floatSourceHandled[sourceLine],
                key: generateRandomString(),
                time: 8,
                style: {
                    top: Math.floor((Math.random() * 80) + 10) + '%',
                    left: Math.floor((Math.random() * 80)) + '%',
                },
            }
            const newFloatingTexts = floatingTexts;
            newFloatingTexts.forEach(floater => floater.time -= 1);
            newFloatingTexts.push(newFloater);

            setFloatingTexts(newFloatingTexts.filter(floater => floater.time > 0));
            setSourceLine((sourceLine + 1) % floatSourceHandled.length)
        }, 1000);

        return () => {
            clearInterval(textAdder);
        }
    });



    const generateRandomString = () => {
        let outString: string = '';
        let inOptions: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 32; i++) {
            outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
        }
        return outString;
    }


    function Noise(props: any) {
        const noise = props.noise;
        if (noise) {
            return <div className={classes.noise}/>;
        }
        return <div/>;
    }

    return (
        <div id="home" className={classes.headerContainer}>
            <div className={classes.header}>
                {floatingTexts.map(float => (
                    <div className={classes.welcome} key={float.key} style={float.style}>
                        {float.text}
                    </div>
                ))}

                <Noise noise={withNoise}/>
            </div>
        </div>
    );
};

export default Catfood;
