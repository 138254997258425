import React from 'react';
import classes from './Bright.module.scss';

const Bright = () => {

    const withNoise = true;

    const source = "Shine Brightly";

    function Noise(props: any) {
        const noise = props.noise;
        if (noise) {
            return <div className={classes.noise}/>;
        }
        return <div/>;
    }

    return (
        <div id="home" className={classes.headerContainer}>
            <div className={classes.header}>
                <span className={classes.quoteContainer}>
                    <span>{source}</span>
                </span>

                <Noise noise={withNoise}/>
            </div>
        </div>
    );
};

export default Bright;
